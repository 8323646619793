import { Product } from '@chassis/shared/models';
import { Factory } from '@chassis/shared/mocks';

export enum VendorPolicyType {
  Faq = 'faq',
  Return = 'return',
  Warranty = 'warranty',
}

export interface VendorPolicy {
  type: string;
  name: string;
  vendor_id: string;
  markdown: string;
  is_default: boolean;
}

export type VendorFaqPolicy = VendorPolicy;
export interface VendorReturnPolicy extends VendorPolicy {
  pdf: string;
  period: string;
}
export interface VendorWarrantyPolicy extends VendorPolicy {
  pdf: string;
  period: string;
  mileage_coverage: string;
  parts_covered: boolean;
  labor_covered: boolean;
  labor_coverage_rate: string;
}

export type VendorPolicyHash = Record<
  VendorPolicyType,
  VendorFaqPolicy | VendorReturnPolicy | VendorWarrantyPolicy
>;

export const getPartType = (hollander: string): string => {
  if (!hollander) {
    return '';
  }
  const parts = hollander.split(/[-.]/);
  return parts[0];
};

export const getVendorPolicyProductKey = (product: Product): string => {
  return [
    product.condition,
    getPartType(product.hollander_number as string),
  ].join('.');
};

export const VendorPolicyFactory: Factory<VendorPolicy> = {
  buildItem: (vendorId) => ({
    type: '',
    name: 'FAQ',
    vendor_id: vendorId.toString(),
    markdown: '### Hello',
    is_default: false,
  }),
  buildList: (count) =>
    [...Array(count).keys()].map(VendorPolicyFactory.buildItem),
};

export const VendorFaqPolicyFactory: Factory<VendorFaqPolicy> = {
  buildItem: (vendorId) => ({
    type: 'VendorFaqPolicy',
    name: 'Mock Faq Policy',
    vendor_id: vendorId.toString(),
    markdown: '### Hello',
    is_default: false,
  }),
  buildList: (count) =>
    [...Array(count).keys()].map(VendorFaqPolicyFactory.buildItem),
};

export const VendorReturnPolicyFactory: Factory<VendorReturnPolicy> = {
  buildItem: (vendorId) => ({
    type: 'VendorReturnPolicy',
    name: 'Mock Return Policy',
    vendor_id: vendorId.toString(),
    markdown: '### Hello',
    pdf: 'http://foobar.com/file.pdf',
    is_default: false,
    period: '30 days',
  }),
  buildList: (count) =>
    [...Array(count).keys()].map(VendorReturnPolicyFactory.buildItem),
};

export const VendorWarrantyPolicyFactory: Factory<VendorWarrantyPolicy> = {
  buildItem: (vendorId) => ({
    type: 'VendorWarrantyPolicy',
    name: 'Mock Warranty Policy',
    vendor_id: vendorId.toString(),
    markdown: '### Hello',
    pdf: 'http://foobar.com/file.pdf',
    is_default: false,
    period: '30 days',
    mileage_coverage: '20k',
    parts_covered: true,
    labor_covered: true,
    labor_coverage_rate: '50',
  }),
  buildList: (count) =>
    [...Array(count).keys()].map(VendorWarrantyPolicyFactory.buildItem),
};

export const VendorPolicyHashFactory = (): VendorPolicyHash => {
  return {
    [VendorPolicyType.Faq]: VendorFaqPolicyFactory.buildItem(1),
    [VendorPolicyType.Return]: VendorReturnPolicyFactory.buildItem(2),
    [VendorPolicyType.Warranty]: VendorWarrantyPolicyFactory.buildItem(1),
  };
};
